import React, { useState } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Pagation from './Pagation';

export default function DataTable({ rows, keys, table_title, onPageChange, maxPages, handleExport, engagement, engagementStatus }) {
    const initialFilters = keys.reduce((acc, key) => {
        acc[key] = '';
        return acc;
    }, {});
    
    const [filters, setFilters] = useState(initialFilters);

    const handleFilterChange = (column, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [column]: value
        }));
    };

    const filterRows = () => {
        return rows.filter(row => {
            return keys.every(key => {
                if (filters[key]) {
                    return String(row[key]).toLowerCase().includes(filters[key].toLowerCase());
                }
                return true;
            });
        });
    };

    const truncateString = (str, maxLength) => {
        if (typeof str !== 'string') return str;
        return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
    };

    const handlePageChange = (page) => {
        onPageChange(page);
    };

    const title = { 'text': table_title, 'path_name': engagement };
    const filteredRows = filterRows();

    return (
        <div className='datatable-container mw-95'>
            {/* Pagination Component */}
            <div className='datatable-pagination'>
                <Pagation maxPages={maxPages} onPageChange={handlePageChange} handleExport={handleExport} title={title} engagementStatus={engagementStatus} />
            </div>

            {/* Data Table */}
            <div className="datatable-scrollable">
                <MDBTable className='datatable-table'>
                    <MDBTableHead>
                        <tr>
                            {keys.map((column) => (
                                <th className='datatable-header' key={column}>
                                    <div className="header-content">
                                        <span>{column.toUpperCase()}</span>
                                        <input
                                            type="text"
                                            className="datatable-filter-input my-auto"
                                            placeholder={`Filter ${column}`}
                                            onChange={(e) => handleFilterChange(column, e.target.value)}
                                            value={filters[column] || ''}
                                        />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {filteredRows.map((row, rowIndex) => (
                            <tr key={row["_id"]} className={rowIndex % 2 === 0 ? 'datatable-row-even' : 'datatable-row-odd'}>
                                {keys.map(key => (
                                    <td key={key} className='datatable-cell'>
                                        {typeof row[key] === 'object' ? truncateString(JSON.stringify(row[key]), 40) : truncateString(row[key], 40)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </div>
        </div>
    );
}
