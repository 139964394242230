import React, { useState, useEffect } from 'react';
import './PrivateSample.css';
import SplashNav from '../components/SplashNav';
import { QRCodeCanvas } from 'qrcode.react';
import API_ENDPOINT from "../config";

const PrivateSample = () => {
  const [selectedProduct, setSelectedProduct] = useState('');
  const [externalFormData, setExternalFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [price, setPrice] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [btcPrice, setBtcPrice] = useState(null); // BTC price in USD
  const [transactionId, setTransactionId] = useState('');
  const [email, setEmail] = useState('');
  const [optionalInfo, setOptionalInfo] = useState({
    name: '',
    company: ''
  });

  // Fetch live BTC price from CoinGecko
  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
        const data = await response.json();
        setBtcPrice(data.bitcoin.usd);
      } catch (error) {
        console.error("Error fetching BTC price:", error);
      }
    };

    fetchBTCPrice();
  }, []);

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
    setExternalFormData({});
    setErrors({});
    setPrice(0); // Reset price when product changes
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Engage - Max Records Validation: Multiple of 1000
    if (name === 'maxRecords' && selectedProduct === 'Engage') {
      if (value % 1000 !== 0) {
        setErrors((prevErrors) => ({ ...prevErrors, maxRecords: 'Max Records must be a multiple of 1000' }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, maxRecords: null }));
      }
    }

    // Harvest - Records Count Validation: Multiple of 100
    if (name === 'recordsCount' && selectedProduct === 'Harvest') {
      if (value % 100 !== 0) {
        setErrors((prevErrors) => ({ ...prevErrors, recordsCount: 'Records Count must be a multiple of 100' }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, recordsCount: null }));
      }
    }

    // Athena - Max Records Validation: Multiple of 10
    if (name === 'maxRecords' && selectedProduct === 'Athena') {
      if (value % 10 !== 0) {
        setErrors((prevErrors) => ({ ...prevErrors, maxRecords: 'Max Records must be a multiple of 10' }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, maxRecords: null }));
      }
    }

    setExternalFormData((prevData) => ({ ...prevData, [name]: value }));
    calculatePrice(selectedProduct, { ...externalFormData, [name]: value });
  };

  const calculatePrice = (product, data) => {
    let price = 0;

    if (product === 'Engage' && data.maxRecords) {
      price = data.maxRecords * 0.15;
    } else if (product === 'Harvest' && data.recordsCount) {
      price = data.recordsCount * 0.80;
    } else if (product === 'Athena' && data.maxRecords) {
      price = data.maxRecords * 2.50;
    }

    setPrice(price);
  };

  const handleFileUpload = (event) => {
    setExternalFormData({ ...externalFormData, file: event.target.files[0] });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOptionalInfoChange = (e) => {
    const { name, value } = e.target;
    setOptionalInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  // Calculate the BTC amount for QR code
  const getPaymentLink = () => {
    if (!price || !btcPrice) return ''; // Return empty if price or BTC rate is unavailable

    if (selectedPayment === 'BTC') {
      const btcAmount = (price / btcPrice).toFixed(8); // Convert USD to BTC
      return `bitcoin:bc1qeq3spv3tkqajk9akhntk0mclmefs87cu8088lh?amount=${btcAmount}`;
    } else if (selectedPayment === 'USDT') {
      return `ethereum:0x0Dcf14eCD6735cBf70fc2f729b6cf75004fC3FbB?amount=${price}`;
    }
    return '';
  };

  const sendEmail = async () => {
    if (!transactionId || !email) {
      alert('Please enter your Transaction ID and Email Address to confirm your request.');
      return;
    }

    // Create a FormData object to handle file upload
    const formData = new FormData();

    Object.keys(externalFormData).forEach(key => {
      formData.append(key, externalFormData[key]);
    });
    formData.append('product', selectedProduct);
    formData.append('transactionId', transactionId);
    formData.append('from', email);
    formData.append('name', optionalInfo.name || 'N/A');
    formData.append('company', optionalInfo.company || 'N/A');
    formData.append('paymentMethod', selectedPayment);
    formData.append('price', `$${price.toFixed(2)}`);

    // Append other form data fields

    // Append file if available
    if (externalFormData.file) {
      formData.append('file', externalFormData.file); // Attach the file directly to the FormData
    }

    try {
      const response = await fetch(`${API_ENDPOINT}/send-email`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Email sent successfully');
      } else {
        alert('Error sending email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending email');
    }
  };
  

  return (
    <div className="private-sample-page">
      <SplashNav />
      <div className="page-content fade-in row">
        <h2>Request a Private Sample</h2>
        <p className="description">
          We understand the importance of privacy and the value of trying out our products. 
          Choose a product from the dropdown and fill in the necessary details to request a 
          private sample. After filling out your request details, a price will appear, it can 
          be paid in BTC or USDT. If you want to discuss bulk pricing options reach out to 
          info@prometheuslabs.app with details of your request. We will always put our clients 
          first and strive for nothing short of excellence.
        </p>

        <label htmlFor="product-dropdown">Select Product:</label>
        <select id="product-dropdown" value={selectedProduct} onChange={handleProductChange}>
          <option value="">-- Select --</option>
          <option value="Engage">Engage</option>
          <option value="Harvest">Harvest</option>
          <option value="Athena">Athena</option>
        </select>

        {/* Conditional Form Fields based on Product Selection */}
        {selectedProduct === 'Engage' && (
          <div className="engage-fields">
            <label>
              Latitude:
              <input type="number" name="latitude" onChange={handleInputChange} required />
            </label>
            <label>
              Longitude:
              <input type="number" name="longitude" onChange={handleInputChange} required />
            </label>
            <label>
              Radius (km):
              <input type="number" name="radius" onChange={handleInputChange} required />
            </label>
            <label>
              Max Records (multiple of 1000):
              <input type="number" name="maxRecords" onChange={handleInputChange} required />
              {errors.maxRecords && <p className="error-message">{errors.maxRecords}</p>}
            </label>
          </div>
        )}

        {selectedProduct === 'Harvest' && (
          <div className="harvest-fields">
            <label>
              Demographic Target:
              <textarea name="demographic" onChange={handleInputChange} required />
            </label>
            <label>
              Max Age:
              <input type="number" name="ageRange" onChange={handleInputChange} required />
            </label>
            <label>
              Intent:
              <input type="text" name="intent" onChange={handleInputChange} required />
            </label>
            <label>
              Records Count (multiple of 100):
              <input type="number" name="recordsCount" onChange={handleInputChange} required />
              {errors.recordsCount && <p className="error-message">{errors.recordsCount}</p>}
            </label>
            <label>
              Geographical Region (US Cities only):
              <input type="text" name="geographicalRegion" onChange={handleInputChange} placeholder="e.g., New York, Los Angeles" />
            </label>
          </div>
        )}

        {selectedProduct === 'Athena' && (
          <div className="athena-fields">
            <label className="file-upload-label">
              Upload CSV/JSON File (List of Facebook URLs):
              <input type="file" accept=".csv, .json" onChange={handleFileUpload} required className="file-upload" />
            </label>
            <label>
              Max Records (multiple of 10):
              <input type="number" name="maxRecords" onChange={handleInputChange} required />
              {errors.maxRecords && <p className="error-message">{errors.maxRecords}</p>}
            </label>
          </div>
        )}

        {/* Display Price if Calculated */}
        {/* Display Price if Calculated */}
        {price > 0 && (
          <div className="price-section">
            <h3>Total Price: ${price.toFixed(2)}</h3>
          </div>
        )}

        {/* Optional Information Section */}
        <div className="optional-info">
          <label>
            Name (Optional):
            <input type="text" name="name" value={optionalInfo.name} onChange={handleOptionalInfoChange} />
          </label>
          <label>
            Company (Optional):
            <input type="text" name="company" value={optionalInfo.company} onChange={handleOptionalInfoChange} />
          </label>
        </div>

        {/* Required Email Field */}
        <div className="required-info">
          <label>
            Email Address (Required):
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
              placeholder="Enter your email for dataset delivery"
            />
          </label>
        </div>

        {/* Payment Selection and QR Code */}
        <div className="payment-section">
          <p>Select a payment method to proceed:</p>
          <select onChange={(e) => setSelectedPayment(e.target.value)}>
            <option value="">-- Select Payment Method --</option>
            <option value="BTC">BTC</option>
            <option value="USDT">USDT</option>
          </select>

          {/* Display QR Code if BTC price and payment method are available */}
          {selectedPayment && btcPrice && (
            <div className="qr-code-section">
              <QRCodeCanvas value={getPaymentLink()} />
              <p>
                {selectedPayment} Address: {selectedPayment === 'BTC' ? "bc1qeq3spv3tkqajk9akhntk0mclmefs87cu8088lh" : "0x0Dcf14eCD6735cBf70fc2f729b6cf75004fC3FbB"}
              </p>
              <p>
                {selectedPayment === 'BTC'
                  ? `Amount in BTC: ${(price / btcPrice).toFixed(8)} BTC`
                  : `Amount in USDT: $${price.toFixed(2)} USDT`}
              </p>
            </div>
          )}
        </div>

        {/* Transaction ID Input for Payment Confirmation */}
        <div className="transaction-id-section">
          <label>
            Transaction ID (Required for Confirmation):
            <input
              type="text"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              placeholder="Enter your transaction ID"
              required
            />
          </label>
        </div>

        {/* Submit Request Button */}
        <button onClick={sendEmail} className="submit-button">
          Submit Request
        </button>
      </div>
    </div>
  );
};

export default PrivateSample;

