import React from 'react';
import SplashNav from '../components/SplashNav';
import UserIntentCard from '../components/UserIntentCard';
import intentData from '../data/intentData.json'; // Sample intent data
// import './HarvestPage.css';

const HarvestPage = () => {
    return (
        <div className="harvest-page">
            <SplashNav />
            <div className="page-content fade-in">
                <div className="title-section row">
                    <img src="/harvest.png" alt="Harvest Logo" className="logo-image" />
                    <h2 className="page-title">Harvest: Intent-Driven List Building</h2>
                    <h6 className="subtitle">Crafting Highly Accurate Lists with Precise Intent Data</h6>
                    <p className="description">
                    Harvest is crafted to empower you in building highly accurate lists, enriched with intent data, that offer targeted insights into individuals' interests and engagement patterns. Together with Athena for detailed persona analysis and Engage for geofencing capabilities, Harvest enables a comprehensive understanding of your audience, allowing you to connect with precision and relevance.
                    </p>
                </div>
                <div className="intent-cards">
                    {intentData.map((user, index) => (
                        <UserIntentCard key={index} user={user} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HarvestPage;
