import React, { useEffect } from 'react';

function fitTextToPage() {
    const textArtElements = document.getElementsByClassName("text-art");
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Loop through each element with the "text-art" class
    Array.from(textArtElements).forEach((textArt) => {
        // Set an initial font size
        let fontSize = 10; // Start with a reasonable default size
        textArt.style.fontSize = `${fontSize}px`;

        // Calculate the ratio to fit width and height
        const scaleWidth = viewportWidth / textArt.offsetWidth;
        const scaleHeight = viewportHeight / textArt.offsetHeight;
        const scale = Math.min(scaleWidth, scaleHeight); // Take the smaller scale to fit both width and height

        // Set the final font size
        fontSize = fontSize * scale;
        textArt.style.fontSize = `${fontSize}px`;
    });
}

// This component calls fitTextToPage on load and window resize
function TextArt({ label, text }) {
    useEffect(() => {
        // Fit text on load and resize
        fitTextToPage();
        window.addEventListener('resize', fitTextToPage);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', fitTextToPage);
        };
    }, []);

    return (
        <pre
            id="art"
            aria-label={label}
            className="text-art"
        >
            {text}
        </pre>
    );
}


export default function AdminArt() {
    const prometheus = "\n" +
        "__/\\\\\\\\\\\\\\\\\\\\\\\\\\______/\\\\\\\\\\\\\\\\\\___________/\\\\\\\\\\_______/\\\\\\\\____________/\\\\\\\\__/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\__/\\\\\\________/\\\\\\__/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\__/\\\\\\________/\\\\\\_____/\\\\\\\\\\\\\\\\\\\\\\___        \n" +
        " _\\/\\\\\\/////////\\\\\\__/\\\\\\///////\\\\\\_______/\\\\\\///\\\\\\____\\/\\\\\\\\\\\\________/\\\\\\\\\\\\_\\/\\\\\\///////////__\\///////\\\\\\/////__\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\///////////__\\/\\\\\\_______\\/\\\\\\___/\\\\\\/////////\\\\\\_       \n" +
        "  _\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_____\\/\\\\\\_____/\\\\\\/__\\///\\\\\\__\\/\\\\\\//\\\\\\____/\\\\\\//\\\\\\_\\/\\\\\\___________________\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_____________\\/\\\\\\_______\\/\\\\\\__\\//\\\\\\______\\///__      \n" +
        "   _\\/\\\\\\\\\\\\\\\\\\\\\\\\\\/__\\/\\\\\\\\\\\\\\\\\\\\\\/_____/\\\\\\______\\//\\\\\\_\\/\\\\\\\\///\\\\\\/\\\\\\/_\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\___________\\/\\\\\\_______\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\_____\\/\\\\\\_______\\/\\\\\\___\\////\\\\\\_________     \n" +
        "    _\\/\\\\\\/////////____\\/\\\\\\//////\\\\\\____\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\__\\///\\\\\\/___\\/\\\\\\_\\/\\\\\\///////____________\\/\\\\\\_______\\/\\\\\\/////////\\\\\\_\\/\\\\\\///////______\\/\\\\\\_______\\/\\\\\\______\\////\\\\\\______    \n" +
        "     _\\/\\\\\\_____________\\/\\\\\\____\\//\\\\\\___\\//\\\\\\______/\\\\\\__\\/\\\\\\____\\///_____\\/\\\\\\_\\/\\\\\\___________________\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_____________\\/\\\\\\_______\\/\\\\\\_________\\////\\\\\\___   \n" +
        "      _\\/\\\\\\_____________\\/\\\\\\_____\\//\\\\\\___\\///\\\\\\__/\\\\\\____\\/\\\\\\_____________\\/\\\\\\_\\/\\\\\\___________________\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_____________\\//\\\\\\______/\\\\\\___/\\\\\\______\\//\\\\\\__  \n" +
        "       _\\/\\\\\\_____________\\/\\\\\\______\\//\\\\\\____\\///\\\\\\\\\\/_____\\/\\\\\\_____________\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_______\\/\\\\\\_______\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\__\\///\\\\\\\\\\\\\\\\\\/___\\///\\\\\\\\\\\\\\\\\\\\\\/___ \n" +
        "        _\\///______________\\///________\\///_______\\/////_______\\///______________\\///__\\///////////////________\\///________\\///________\\///__\\///////////////_____\\/////////_______\\///////////_____\n"
    const admin = "\n" +
        "_____/\\\\\\\\\\\\\\\\\\_____/\\\\\\\\\\\\\\\\\\\\\\\\_____/\\\\\\\\____________/\\\\\\\\__/\\\\\\\\\\\\\\\\\\\\\\__/\\\\\\\\\\_____/\\\\\\_        \n" +
        " ___/\\\\\\\\\\\\\\\\\\\\\\\\\\__\\/\\\\\\////////\\\\\\__\\/\\\\\\\\\\\\________/\\\\\\\\\\\\_\\/////\\\\\\///__\\/\\\\\\\\\\\\___\\/\\\\\\_       \n" +
        "  __/\\\\\\/////////\\\\\\_\\/\\\\\\______\\//\\\\\\_\\/\\\\\\//\\\\\\____/\\\\\\//\\\\\\_____\\/\\\\\\_____\\/\\\\\\/\\\\\\__\\/\\\\\\_      \n" +
        "   _\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\\\///\\\\\\/\\\\\\/_\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\//\\\\\\_\\/\\\\\\_     \n" +
        "    _\\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\__\\///\\\\\\/___\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\\\//\\\\\\\\/\\\\\\_    \n" +
        "     _\\/\\\\\\/////////\\\\\\_\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\____\\///_____\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\_\\//\\\\\\/\\\\\\_   \n" +
        "      _\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\_______/\\\\\\__\\/\\\\\\_____________\\/\\\\\\_____\\/\\\\\\_____\\/\\\\\\__\\//\\\\\\\\\\\\_  \n" +
        "       _\\/\\\\\\_______\\/\\\\\\_\\/\\\\\\\\\\\\\\\\\\\\\\\\/___\\/\\\\\\_____________\\/\\\\\\__/\\\\\\\\\\\\\\\\\\\\\\_\\/\\\\\\___\\//\\\\\\\\\\_ \n" +
        "        _\\///________\\///__\\////////////_____\\///______________\\///__\\///////////__\\///_____\\/////__\n"
    return (
        <div>
            <TextArt label="Admin" text={prometheus}/>
            <TextArt label="Admin" text={admin}/>
        </div>
    );
}




