import React from 'react';
import './GeofenceCard.css';

const GeofenceCard = ({ geofence }) => {
    return (
        <div className="geofence-card my-2 text-center">
            <h3 className="geofence-title">{geofence.city}, {geofence.state}</h3>
            <p className="geofence-metadata"><strong>Coordinates:</strong> {geofence.location.latitude}, {geofence.location.longitude}</p>
            <p className="geofence-metadata"><strong>Radius:</strong> {geofence.radius_km} km</p>
            <p className="geofence-metadata"><strong>Zip Code:</strong> {geofence.zip}</p>
            <p className="geofence-metadata"><strong>Record Count:</strong> {geofence.record_count}</p>
            <div className="addresses-section">
                <h4>Examples Within Geofence</h4>
                <ul>
                    {geofence.addresses.map((address, index) => (
                        <li key={index}>
                            {address.address_1}, {address.city}, {address.state} {address.zip}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default GeofenceCard;
