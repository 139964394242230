import React from 'react';
// import './InterestSection.css';

const InterestSection = ({ interests }) => (
    <div className="interest-section">
        {Object.keys(interests).map((category, index) => (
            <div key={index} className="interest-category">
                <h3>{category}</h3>
                <ul>
                    {interests[category].map((item, idx) => (
                        <li key={idx}>{item}</li>
                    ))}
                </ul>
            </div>
        ))}
    </div>
);

export default InterestSection;
