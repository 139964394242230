import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import Login from "./pages/login";
import Admin from "./pages/admin";
import AdminLogin from "./pages/admin_login"
import SplashPage from "./pages/SplashPage";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Contact from "./components/Contact";
import AthenaPage from "./pages/AthenaPage";
import EngagePage from "./pages/EngagePage";
import HarvestPage from "./pages/HarvestPage";
import MineSample from "./pages/MineSample";
import PrivateSample from "./pages/PrivateSample";
import ArtemisPage from "./pages/ArtemisPage";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<SplashPage/>}/>
                <Route exact path="/about-us" element={<AboutUs/>}/>
                <Route exact path="/privacy" element={<PrivacyPolicy/>}/>
                <Route exact path="/private-sample" element={<PrivateSample/>}/>
                <Route exact path="/contact" element={<Contact/>}/>
                <Route exact path="/home" element={<Home/>}/>
                <Route exact path="/athena-sample" element={<AthenaPage/>}/>
                <Route exact path="/engage-sample" element={<EngagePage/>}/>
                <Route exact path="/harvest-sample" element={<HarvestPage/>}/>
                <Route exact path="/mine-sample" element={<MineSample/>}/>
                <Route exact path="/artemis" element={<ArtemisPage/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/admin" element={<Admin/>}/>
                <Route exact path="/admin_login" element={<AdminLogin/>}/>
            </Routes>
        </Router>
    );
}

export default App;
