import React from 'react';
import SplashNav from './SplashNav'; // Assuming SplashNav is your navigation component
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <SplashNav />
      <div className="contact-content fade-in">
        <h1 className="contact-title text-center">Contact Us</h1>

        {/* Cities Section */}
        <section className="cities-section">
          <h2 className="section-title my-5">PRIMARY LOCATIONS</h2>
          <div className="city-list">
            <div className="city">
              <h3>Chicago, IL, USA</h3>
              <p>Phone: AVAILABLE UPON REQUEST</p>
            </div>
            <div className="city">
              <h3>Athens, Greece</h3>
              <p>Phone: AVAILABLE UPON REQUEST</p>
            </div>
            <div className="city">
              <h3>Ontario, Canada</h3>
              <p>Phone: AVAILABLE UPON REQUEST</p>
            </div>
          </div>
        </section>

        {/* Email Section */}
        <section className="email-section row">
          <h2 className="section-title">Email</h2>
          <div className="email-box">
            <a href="mailto:info@prometheuslabs.app" className="email-link">
              info@prometheuslabs.app
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
