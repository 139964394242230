import React from 'react';
import SplashNav from './SplashNav';
import './AboutUs.css';

export default function AboutUs() {
  return (
    <div className="about-us-page">
      <SplashNav />

      {/* About Us Section */}
      <section className="about-us-section mt-5">
        <div className="about-us-content team-highlight row">
          <h1 className="about-title fade-in mt-5">OUR APPROACH</h1>
          <p className="about-description fade-in center">
          At Prometheus Labs, by deploying our proprietary software, Prometheus, we specialize in tackling complex problems by leveraging unique points of intelligence. 
          Our approach goes beyond traditional data analysis—we focus on extracting hidden insights and transforming them 
          into strategic, actionable solutions. Whether navigating intricate challenges or uncovering unseen opportunities, 
          we empower organizations to make informed decisions, adapt to dynamic environments, and achieve success through the 
          intelligent application of data.
          </p>
          <div className="fade-in">
            <h2 className="team-title">OUR TEAM</h2>
            <p className="team-description">
              Our team is comprised of seasoned experts with backgrounds in data science, forensic accounting, political analysis, and consulting. We have a proven track record of solving some of the most challenging problems
              for political organizations, industry leaders, and innovative startups. Our team takes engagements on a case by case basis after conducting an initial review of an incoming request.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
