import React from 'react';
import './EngagementsSummary.css';

class EngagementsSummary extends React.Component {
  render() {
    const { engagements } = this.props;

    return (
      <div className="engagements-summary-container">
        <div className="engagements-table-wrapper">
          <table className="engagements-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Desc.</th>
                <th>Contract</th>
                <th>Product</th>
              </tr>
            </thead>
            <tbody>
              {engagements.map((engagement, index) => (
                <tr key={index}>
                  <td className="mobile-text" data-label="Name">{engagement.name}</td>
                  <td className={`mobile-text text-upper ${engagement.status === "inactive" ? "text-red" : "text-green"}`} data-label="Status">
                      {engagement.status}
                  </td>
                  <td className="mobile-text" data-label="Description">{engagement.description}</td>
                  <td className="mobile-text" data-label="Contract">{engagement.contract}</td>
                  <td className="mobile-text" data-label="Product">{engagement.product}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EngagementsSummary;
