import React, { Component, createRef } from "react";
import Cookies from "universal-cookie";
import L from "leaflet";
import markerIcon from "../map_marker.png";
import axios from "axios";
import "./admin.css";
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import UserTable from "../components/UserTable";
import { Navigate } from "react-router-dom";
import API_ENDPOINT from "../config";
import Navbar from "../components/Navbar";

class Admin extends Component {
    constructor(props) {
        super(props);
        const web_cookies = new Cookies();

        this.state = {
            authenticated: false,
            verifying: true,
            active_state: "ALL STATES",
            cookies: web_cookies,
            username: null,
            password: null,
            password_set: true,
            data: null,
            states_under_contract: ["MI", "WI", "PA"],
            invalid_credentials: false,
            width: 700,
            height: 500,
            markers: [],
            user_data: [],
            showTyping: true,
            fadeOut: false,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.verifyAdminSession = this.verifyAdminSession.bind(this);
        this.getPositions = this.getPositions.bind(this);

        this.customIcon = new L.Icon({
            iconUrl: markerIcon,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
        });

        // Create a ref for MapContainer
        this.mapRef = createRef();
    }

    handleMarkerClick = (lat, long) => {
        const map = this.mapRef.current; // Get map instance from ref
        if (map) {
            map.setView([lat, long], 15); // Set center and zoom
        }
    };

    updateDimensions() {
        if (window.innerWidth < 768) {
            this.setState({ authenticated: false, width: 300, height: 300 });
        } else {
            this.setState({ width: 700, height: 500 });
        }
    }

    async verifyAdminSession() {
        const baseURL = `${API_ENDPOINT}/verify_admin_session`;

        try {
            await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                    "admin-key": this.state.cookies.get("admin-key"),
                }
            });
            this.setState({ admin_authenticated: true, verifying: false });
        } catch (err) {
            console.log(err);
            if (err.response && err.response.status === 403) {
                this.setState({ authenticated: false, verifying: false });
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
        }
    }

    async getPositions() {
        const baseURL = `${API_ENDPOINT}/admin`;

        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                    "admin-key": this.state.cookies.get("admin-key"),
                }
            });
            this.state.cookies.set('session-key', res.data["_session-key"], { path: '/', secure: true });
            let location_list = [];
            for (let user in res.data.data) {
                for (let ip in res.data.data[user]["access_points"]) {
                    let location_data = res.data.data[user]["access_points"][ip]["location_data"];
                    if (location_data !== "None") {
                        location_list.push({
                            "lat": location_data["lat"],
                            "long": location_data["lon"],
                            "message": res.data.data[user]["access_points"][ip]["ip"] + "<br />" + res.data.data[user]["user_id"],
                        });
                    }
                }
            }
            this.setState({ user_id: res.data.user, markers: location_list, user_data: res.data.data });
        } catch (err) {
            if (err.response && err.response.status === 403) {
                console.log("Unauthenticated");
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
        }
    }

    componentDidMount() {
        this.verifyAdminSession();
        this.getPositions();
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);

        setTimeout(() => {
            this.setState({ fadeOut: true });
        }, 4000);

        setTimeout(() => {
            this.setState({ showTyping: false });
        }, 5000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        if (this.state.verifying) {
            return null;
        }

        if (this.state.admin_authenticated) {
            return (
                <div className="Admin fade-in">
                    <Navbar />
                    <div className='main-content-2'>
                        <div className='col-12 col-md-12 map-box px-5 mt-3'>
                            <MapContainer
                                className="map-container"
                                center={[40, 0]}
                                zoom={3}
                                scrollWheelZoom={true}
                                style={{ height: '500px', width: '100%' }}
                                ref={this.mapRef} // Assign the ref to MapContainer
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {this.state.markers.length > 0 &&
                                    this.state.markers.map((marker, index) => (
                                        <Marker
                                            key={index}
                                            position={[marker.lat, marker.long]}
                                            icon={this.customIcon}
                                            eventHandlers={{
                                                click: () => this.handleMarkerClick(marker.lat, marker.long),
                                            }}
                                        >
                                            <Popup>
                                                <div dangerouslySetInnerHTML={{ __html: marker.message }} />
                                            </Popup>
                                        </Marker>
                                    ))}
                            </MapContainer>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-12 pt-2' style={{ background: "black" }}>
                            {this.state.user_data.length > 0 ? (
                                <UserTable rows={this.state.user_data} handleMarkerClick={this.handleMarkerClick} />
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Navigate replace to={'/admin_login'} />;
        }
    }
}

export default Admin;
