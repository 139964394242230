import React, { Component } from 'react';
import { Puff } from 'react-loading-icons';
import { Button, ButtonGroup } from 'react-bootstrap'; // React Bootstrap

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isLoading: false,
    };
  }

  handlePageChange = async (page) => {
    this.setState({ isLoading: true, currentPage: page });

    try {
      await this.props.onPageChange(page);
    } catch (error) {
      console.error('Error during page change:', error);
    }

    await sleep(2000); // Optional: adds a delay
    this.setState({ isLoading: false });
  };

  handleNext = () => {
    const { currentPage } = this.state;
    const { maxPages } = this.props;
    if (currentPage < maxPages) {
      const nextPage = currentPage + 1;
      this.handlePageChange(nextPage);
    }
  };

  handleBack = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      this.handlePageChange(prevPage);
    }
  };

  handleFirst = () => this.handlePageChange(1);

  handleLast = () => {
    const { maxPages } = this.props;
    this.handlePageChange(maxPages);
  };

  helperHandleExport = async () => {
    this.setState({ isLoading: true });
    try {
      await this.props.handleExport(); // Ensure handleExport is invoked correctly
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { maxPages, title, engagementStatus } = this.props;
    const { currentPage, isLoading } = this.state;
    const maxVisibleButtons = 5;
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(maxVisibleButtons / 2), maxPages - maxVisibleButtons + 1));
    const visiblePages = Array.from({ length: Math.min(maxVisibleButtons, maxPages) }, (_, i) => startPage + i);

    return (
      <div className='pagination-container d-flex align-items-center'>
        <div className='pagination-logo'>
          <img className='pagination-image' src={`/${title["path_name"]}.png`} alt="engagement_logo" />
        </div>
        <div className='pagination-title text-center'>
          <h6 className='pagination-text bold text-white'>{title["text"]}</h6>
        </div>
        <div className='pagination-loader d-none d-md-flex'>
          {isLoading && (
            <>
              <Puff stroke="#121212" strokeOpacity={1} speed={1.75} height={40}/>
              <p className='loading-text'>Loading</p>
            </>
          )}
        </div>
        <div className='pagination-buttons-container'>
          <ButtonGroup className="pagination-buttons">
            <Button className='pagination-btn' onClick={this.handleFirst} disabled={currentPage === 1}>
              FIRST
            </Button>

            <Button className='pagination-btn' onClick={this.handleBack} disabled={currentPage === 1}>
              PREV
            </Button>

            {visiblePages.map((page) => (
              <Button
                className={`pagination-btn ${page === currentPage ? 'selected' : ''}`}
                key={page}
                variant={page === currentPage ? 'primary' : 'outline-primary'}
                onClick={() => this.handlePageChange(page)}
              >
                {page}
              </Button>
            ))}

            <Button className='pagination-btn' onClick={this.handleNext} disabled={currentPage === maxPages}>
              NEXT
            </Button>

            <Button className='pagination-btn' onClick={this.handleLast} disabled={currentPage === maxPages}>
              LAST
            </Button>
            {engagementStatus ? (
                <Button onClick={this.helperHandleExport} className="pagination-btn">
                  EXPORT
                </Button>
              ) : null}
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default Pagination;
