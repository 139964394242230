import React from 'react';
import SplashNav from './SplashNav';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <SplashNav />
      <div className="privacy-content fade-in">
        <h1 className="privacy-title">Privacy Policy</h1>

        {/* Introduction Section */}
        <section className="privacy-section">
          <h2 className="section-title">Honor</h2>
          <p className="privacy-paragraph">
            At Prometheus Labs, safeguarding the privacy and confidentiality of our clients is at the core of our values. We believe that maintaining trust is paramount, and we have implemented robust measures to ensure that all client information remains secure, segregated, and protected throughout every engagement.
          </p>
        </section>

        {/* Data Security and Confidentiality */}
        <section className="privacy-section">
          <h2 className="section-title">Encryption</h2>
          <p className="privacy-paragraph">
            Each client’s data is treated with the utmost care. We maintain stringent protocols to ensure that client information is never shared, disclosed, or used for any purposes beyond the engagement without explicit consent. All research, analysis, and insights are stored securely in isolated environments to prevent cross-contamination between client projects.
          </p>
          <p className="privacy-paragraph">
            Our systems are fortified with industry-leading encryption standards, and only authorized personnel are granted access to sensitive data. We regularly review our security policies and upgrade our infrastructure to stay ahead of evolving threats and ensure that data is protected against any unauthorized access or breaches.
          </p>
        </section>

        {/* Commitment to Client Trust */}
        <section className="privacy-section">
          <h2 className="section-title">Responsibility</h2>
          <p className="privacy-paragraph">
            We take our clients' trust seriously. At Prometheus Labs, no two engagements are the same, and we ensure that client data and intellectual property remain exclusive to the respective client. We do not share intelligence across engagements. This commitment allows us to provide a tailored, high-level service that guarantees the uniqueness of every solution we deliver.
          </p>
          <p className="privacy-paragraph">
            Our team is bound by strict confidentiality agreements, ensuring that sensitive insights, strategies, and proprietary data remain protected. We never disclose the nature of our work or client relationships without explicit authorization.
          </p>
        </section>

        {/* Information Storage and Retention */}
        <section className="privacy-section">
          <h2 className="section-title">Management</h2>
          <p className="privacy-paragraph">
            We store client information only for the duration necessary to fulfill the objectives of each project. Upon project completion, all client data is securely archived or deleted based on the client’s preferences and legal requirements. We are committed to complying with relevant data protection laws and regulations to safeguard client interests at all times.
          </p>
        </section>

        {/* Client Rights and Contact */}
        <section className="privacy-section">
          <h2 className="section-title">Ethics</h2>
          <p className="privacy-paragraph">
            Clients have full control over their data and can request access, correction, or deletion at any time. Should any concerns or questions arise regarding our privacy practices, we encourage clients to contact us directly for clarification or support.
          </p>
        </section>

        {/* Security */}
        <section className="privacy-section">
          <h2 className="section-title">Security</h2>
          <p className="privacy-paragraph">
          As your trusted partner, we are committed to helping you achieve your end objectives while keeping your intellectual property, data, and strategic insights fully protected. From the planning phase to final delivery, your security is our top priority, ensuring that you can navigate complex environments confidently and successfully.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
