import React from 'react';
import InterestSection from './InterestSection';
// import './PersonCard.css';

const PersonCard = ({ person }) => {
    return (
        <div className="person-card">
            <h2 className="person-name">{person.name}</h2>
            <p className="person-location">
                <strong>Location:</strong> {person.city}, {person.state} {person.zip}
            </p>
            <p className="person-address">
                <strong>Address:</strong> {person.address}
            </p>

            <InterestSection interests={person.categorized_interests} />

            <p className="character-analysis">
                <strong>Character Analysis:</strong> {person.character_analysis}
            </p>
        </div>
    );
};

export default PersonCard;
