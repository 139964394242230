// import {Puff} from "react-loading-icons";
import React, {Component} from "react";
import Cookies from "universal-cookie";
import L from "leaflet";
import markerIcon from "../map_marker.png";
import axios from "axios";
import  { Navigate } from 'react-router-dom'
import "./login.css"
import API_ENDPOINT from "../config";
import { Puff } from 'react-loading-icons';
import SplashNav from "../components/SplashNav";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Login extends Component {

    constructor(props) {

        super(props);
        const web_cookies = new Cookies();
        // web_cookies.set('session-key', null, { path: '/' });
        // web_cookies.set('bearer', null, { path: '/' });

        this.state = {
            authenticated: false,
            verifying: true,
            active_state: "ALL STATES",
            cookies: web_cookies,
            username: null,
            password: null,
            password_set: true,
            data: null,
            states_under_contract: ["MI", "WI", "PA"],
            invalid_credentials: false,
            width: 700,
            height: 500,
            markers: [],
            error: false

        };
        this.updateDimensions = this.updateDimensions.bind(this);

        this.customIcon = new L.Icon({
            iconUrl: markerIcon,
            iconSize: [32, 32], // size of the icon
            iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
        });
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    verifySession = async () => {
        const baseURL = `${API_ENDPOINT}/verify_session`;

        try {
            await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                }
            });

        this.setState({authenticated: true, verifying: false})
        return null;
        } catch (err) {
            console.log(err)
            if (err.response === undefined) {
                this.setState({error: true})
                this.setState({authenticated: false, verifying: false})
                return null;
            } 
            if (err.response.status === 403) {
                this.setState({authenticated: false, verifying: false})
            }
            console.log(`Err @ [ App.jsx - getData ] ::: ${err}`);
            return null;  // Return null or handle the error appropriately
        }
    };

    componentDidMount() {
        this.verifySession()
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }


    updateDimensions() {
        if (window.innerWidth < 768) { // Consider mobile if screen width is less than 768px
            this.setState({authenticated: false})
            this.setState({width: 300, height: 300});
        } else {
            this.setState({width: 700, height: 500});
        }
    };
    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleLogin();  // Trigger submit when Enter is pressed
        }
    };

    handlePasswordChange = (password) => {
        this.setState({password: password})
    }

    handleUsernameChange = (username) => {
        this.setState({username: username})
    }

    handleLogin = async () => {
        const baseURL = `${API_ENDPOINT}/login`;
        this.setState({login_loading: true});
        await sleep(1500)
        try {
            const res = await axios.post(baseURL, {
                username: this.state.username,
                password: this.state.password
            });
            this.state.cookies.set('session-key', res.data["session-key"], {path: '/', secure: true});
            this.state.cookies.set('bearer', res.data["bearer"], {path: '/', secure: true});
            this.setState({authenticated: true});
            return null  // Return the data directly

        } catch (err) {
            console.log(`Err @ [ App.jsx - loginError ] ::: ${err}`);
            this.setState({login_loading: false});
            this.setState({invalid_credentials: true})
            return null;  // Return null or handle the error appropriately
        } finally {
            this.setState({login_loading: false});
        }
    }



    render() {
        const { verifying } = this.state;
    
        if (verifying) {
            return (
                <>
                    {/* You can add a loading indicator or something here */}
                </>
            );
        }
    
        return (
            <div className="new-login-page">
                <SplashNav />
                <div className="new-login-container">
                    <div className="new-login-header">
                        <h1>PROMETHEUS</h1>
                        <h3>By Prometheus Labs</h3>
                    </div>
                    <div className="new-login-form">
                        <input
                            className="new-login-input"
                            placeholder="Username"
                            type="text"
                            onChange={e => this.handleUsernameChange(e.target.value)}
                            onKeyDown={this.handleKeyPress}
                        />
                        <input
                            className="new-login-input"
                            placeholder="Password"
                            type="password"
                            onChange={e => this.handlePasswordChange(e.target.value)}
                            onKeyDown={this.handleKeyPress}
                        />
                        <button className="new-login-button" onClick={() => this.handleLogin()}>
                            {this.state.login_loading ? (
                                <Puff stroke="#ffffff" height={24} />
                            ) : (
                                "Submit"
                            )}
                        </button>
                        <label style={{color:"#999", fontSize:"10px"}}>For questions regarding login credentials: Email us at info@prometheuslabs.app</label>
                    </div>
                    {this.state.invalid_credentials && (
                        <p className="new-login-error">Invalid Credentials</p>
                    )}
                    {this.state.error && (
                        <p className="new-login-error">Unable to Connect to Host</p>
                    )}
                    {this.state.authenticated && <Navigate replace to={'/home'} />}
                </div>
            </div>
        );
    }
    
}
export default Login;
