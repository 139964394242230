import React, {Component} from 'react';
import Navbar from "../components/Navbar";
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Navigate} from "react-router-dom"
import axios from "axios";
import {Puff} from 'react-loading-icons';
import Cookies from 'universal-cookie';
import API_ENDPOINT from "../config";
import DataTable from '../components/DataTable';
import EngagementsSummary from '../components/EngagementsSummary';

class App extends Component {

    constructor(props) {

        super(props);
        const web_cookies = new Cookies();

        this.state = {
            authenticated: false,
            verifying: true,
            page: 1,
            active_state: "",
            cookies: web_cookies,
            username: null,
            password: null,
            password_set: true,
            data_raw: null,
            data_enriched: null,
            raw_keys:null,
            enriched_keys:null,
            invalid_credentials: false,
            width: 700,
            height: 500,
            markers: [],
            error: false,
            loading: false,
            engagements: null,
            selected_engagement: undefined,
            selected_engagement_status: undefined,
            currentIndex: 0

        };
        this.authenticated = false
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // merge
    verifySession = async () => {
        const baseURL = `${API_ENDPOINT}/verify_session`;

        try {
            const response = await axios.get(baseURL, {
                headers: {
                    "session-key": this.state.cookies.get("session-key"),
                    "bearer": this.state.cookies.get("bearer"),
                }
            });

            if (response.data && response.data.engagements) {
            } else {
                console.error('Engagements data is missing or not formatted correctly', response.data);
            }

            let prev_path = "";
            let next_path = "";

            try {
                if (response.data.engagements.length <= 1) {
                    prev_path = "";
                    next_path = ""
                } else {
                    prev_path = `/${response.data.engagements[response.data.engagements.length - 1]["path_name"]}.png`;
                    next_path = `/${response.data.engagements[1]["path_name"]}.png`;
                }

            } catch (error) {
                prev_path = "";
                next_path = "";
            }
            

            this.setState({authenticated: true,
                           verifying: false,
                           engagements: response.data.engagements,
                           selected_engagement:response.data.engagements[0]["path_name"],
                           selected_engagement_status: response.data.engagements[0]["status"] === "active",
                           previous_engagement_path: prev_path,
                           next_engagement_path: next_path
                        })

            return null;
        } catch (err) {
            console.log(err)
            this.setState({error: true})
            if (err.response === undefined) {
                this.setState({authenticated: false, verifying: false})
                return null;
            } 
            if (err.response.status === 403) {
                this.setState({authenticated: false, verifying: false})
            }
            console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
            return null;  // Return null or handle the error appropriately
        }
    };

    componentDidMount() {
        this.verifySession()
        // this.updateDimensions();
        // window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.updateDimensions);
    }

    handlePageChange = async (page) => {
        await this.paginateState(page);
    };


    getUserData = async (page) => {
        const baseURL = `${API_ENDPOINT}/pet-data`;
    
        try {
            const res = await axios.get(baseURL, {
                headers: {
                  "session-key": this.session_key,
                  "bearer": this.state.cookies.get("bearer"),
                  "page": page
                }
            });
    
            this.session_key = res.data["_session"];
            
            return res.data.data;
    
        } catch (err) {
            if (err.response && err.response.status === 403) {
                this.setState({authenticated: false});
            } else if (err.response && err.response.status === 429) {
                alert("Please slow down your requests. You have been rate-limited.");
                this.setState({loading: false});
            } else {
                console.log(`Err @ [ App.jsx - getStateData ] ::: ${err}`);
                this.setState({error: true});
            }
            return null; 
        }
    };
    

    handleExport = async () => {
        this.setState({ loading: true, error: false });
        try {
          const response = await axios.get(`${API_ENDPOINT}/export`, {
            headers: {
              "session-key": this.state.session_key || this.state.cookies.get("session-key"),
              "bearer": this.state.cookies.get("bearer"),
              "state": this.state.new_state,
              "engagement": this.state.selected_engagement,
              "page": 0
            }
          });
      
          // Set the new session-key from the response, if available
          if (response.data["_session"]) {
            this.state.cookies.set('session-key', response.data["_session"], { path: '/', secure: true });
          }
      
          // Create and download the file
          const bytes = new TextEncoder().encode(JSON.stringify(response.data.data));
          const url = window.URL.createObjectURL(new Blob([bytes], { type: "application/json;charset=utf-8" }));
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.state.selected_engagement}.json`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
      
          this.setState({ loading: false });
        } catch (error) {
          console.error('There was a problem with the export:', error);
          this.setState({ loading: false, error: true });
        }
      };

    getAllData = async (page = 1) => {
        const baseURL = `${API_ENDPOINT}/data`;

        try {
            const res = await axios.get(baseURL, {
                headers: {
                    "session-key": this.session_key,
                    "bearer": this.state.cookies.get("bearer"),
                    "page": page,
                    "engagement": this.state.selected_engagement,
                }
            });
            this.session_key = res.data["_session"]
            return res.data;
        } catch (err) {
            if (err.response.status === 403) {
                this.setState({authenticated: false})
            } else if (err.response.status === 429) {
                alert("Please slow down your requests. You have been rate limited")
                this.setState({loading: false})
            }

            console.log(`Err @ [ App.jsx - getAllData ] ::: ${err}`);

            return null;
        }
    };

    loadData = async (event) => {
        this.setState({loading: true});

        try {
            let data_raw, data_enriched;
            this.session_key = this.state.cookies.get('session-key')
            let data = await this.getAllData();
            data_raw = data["data_raw"]
            data_enriched = data["data_enriched"]
            if (data_raw !== null && data_raw !== undefined) {
                this.setState({
                    data_raw: data_raw,
                    data_enriched: data_enriched,
                    max_pages: 5,
                    loading: false,
                    raw_keys: data.raw_keys,
                    enriched_keys: data.enriched_keys
                })

            }
            this.state.cookies.set('session-key', this.session_key, {path: '/', secure: true})
        } catch (error) {
            this.setState({error: true})
            console.error('Error fetching state data:', error);
            this.setState({loading: false});
        }
    };

    setActiveEngagement = async (engagement) => {
        const engagement_status = this.state.engagements.some(
            (ele) => ele["path_name"] === engagement["path_name"] && ele["status"] === "active"
        );
    
        // Await state update before calling loadData
        await this.setState({
            selected_engagement: engagement["path_name"],
            selected_engagement_status: engagement_status,
        });
    
        // Load data after state has been set
        await this.loadData();
    };

    paginateState = async (page) => {
        this.setState({ loading: true, currentPage: page });
    
        try {
            let data_raw, data_enriched;
            this.session_key = this.state.cookies.get('session-key');
            
            const data = await this.getAllData(page);
            data_raw = data.data_raw;
            data_enriched = data.data_enriched;
    
            if (data_raw !== null && data_raw !== undefined) {
                this.setState({
                    data_raw: data_raw,
                    data_enriched: data_enriched,
                    max_pages: 5,
                    loading: false
                });
            }
    
            this.state.cookies.set('session-key', this.session_key, { path: '/', secure: true });
        } catch (error) {
            this.setState({ error: true, loading: false });
            console.error('Error fetching state data:', error);
        }
    };

    Logout = () => {
        this.state.cookies.remove('session-key')
        this.state.cookies.remove('berer')
        this.setState({authenticated: false, cookies: null, verifying: false});
    }

      reset = () => {
        this.setState(
            {
                data_raw: null,
                data_enriched: null
            }
        )
      }

      render() {
        const { verifying, authenticated } = this.state;
    
        if (verifying) {
            return (
                <>
                </>
            );
        }
    
        return (
            <div className="App">
                <Navbar Logout={this.Logout} />
                {authenticated === true ? (
                    <div className="main-content fade-in">
                        <div className="row d-flex justify-content-start mx-5 my-2">
                            <button
                                className="col-10 col-md-1 modern-button py-2 fade-in b-c no-mobile sticky-reset-button"
                                onClick={() => this.reset()}
                            >
                                RESET
                            </button>
                        </div>
                        <div className="only-mobile">
                            <p className="soft-text">Log in on Desktop to See Information Regarding Your Contracts</p>
                        </div>
                        <div className="row mb-3 mw-95 mx-auto">
                            <EngagementsSummary engagements={this.state.engagements} />
                        </div>
    
                        <div className="row no-mobile">
                            {this.state.data_raw !== null && this.state.data_raw !== undefined && this.state.data_raw.length > 0 ? (
                                <div className="row justify-content-center b-c">
                                    <div className="fade-in">
                                        <DataTable
                                            rows={this.state.data_raw}
                                            keys={this.state.raw_keys}
                                            table_title="Raw Data Table"
                                            maxPages={this.state.max_pages}
                                            onPageChange={this.handlePageChange}
                                            handleExport={this.handleExport}
                                            engagement={this.state.selected_engagement}
                                            engagementStatus={this.state.selected_engagement_status}
                                        />
                                    </div>
    
                                    <div className="separation"></div>
    
                                    {this.state.data_enriched !== null && this.state.data_enriched !== undefined && this.state.data_enriched.length > 0 ? (
                                        <div className="fade-in">
                                            <DataTable
                                                rows={this.state.data_enriched}
                                                keys={this.state.enriched_keys}
                                                table_title="Enriched Data Table"
                                                maxPages={this.state.max_pages}
                                                onPageChange={this.handlePageChange}
                                                handleExport={this.handleExport}
                                                engagement={this.state.selected_engagement}
                                                engagementStatus={this.state.selected_engagement_status}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
    
                                    <div className="separation"></div>
                                </div>
                            ) : (
                                <div className="row justify-content-center align-items-center">
                                    {this.state.loading ? (
                                        <div className="text-center">
                                            <Puff stroke="#121212" strokeOpacity={1} speed={1.75} height={88} />
                                        </div>
                                    ) : (
                                        <>
                                            {/* Engagement Cards Grid */}
                                            <div className="engagements-display-container mw-95">
                                                <div className="engagements-grid">
                                                    {this.state.engagements && this.state.engagements.map((engagement, index) => (
                                                        <div
                                                            key={index}
                                                            className="engagement-card"
                                                            style={{
                                                                backgroundImage: `url(${engagement.path_name}.png)`,
                                                            }}
                                                            onClick={() => this.setActiveEngagement(engagement)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Navigate replace to={"/login"} />
                )}
            </div>
        );
    }
    
}

export default App;
