import React, { useState } from 'react';
import './SplashNav.css'; // Import the associated CSS file
import { useNavigate } from 'react-router-dom';

export default function SplashNav(props) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [rotation, setRotation] = useState(0);
  const scrollY = props.scrollY ? props.scrollY : 0;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleHover = () => {
    setRotation(rotation + 90); // Increment rotation by 90 degrees on each hover
};

  return (
    <header className={`splash-container p-0 ${scrollY * 6.5 > 200 ? "see-through" : "reverse-see-through"}`}>
      <div className={`splash ${scrollY * 6.5 > 200 ? "no-pad-smooth" : "re-pad-smooth"}`}>
        <div className="splash-logo">
            <img src="/favicon.ico" alt="Prometheus Logo" className="logo" />
            <span className="logo-text" onClick={() => navigate('/')}>PROMETHEUS</span>
        </div>
        <div className="splash-menu-icon" onClick={toggleMenu} onMouseEnter={handleHover}>
          {/* Menu Icon */}
          <div
            className="hexagon"
            style={{ transform: `rotate(${rotation}deg)` }}
            >
            <div className="menu-lines"></div>
          </div>
        </div>
        <div className={`splash-menu mt-3 ${isMenuOpen ? 'active fade-in' : ''}`}>
                <li><button className="splash-contact my-2" onClick={() => navigate('/about-us')}>ABOUT US</button></li>
                <li><button className="splash-contact my-2" onClick={() => navigate('/privacy')}>PRIVACY POLICY</button></li>
                <li><button className="splash-contact my-2" onClick={() => navigate('/contact')}>CONTACT US</button></li>
                {/* <li><button className="splash-contact my-2" onClick={() => navigate('/login')}>MEMBERS AREA</button></li> */}
                <li><button className="splash-contact my-2" onClick={() => props.Logout()}>SIGN OUT</button></li>
        </div>
      </div>
    </header>
  );
}
