import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    { month: 'Initial', DataCost: 3000, MarketingSpend: 500, AdSpend: 500 },
    { month: 'Month 1', DataCost: 0, MarketingSpend: 500, AdSpend: 700 },
    { month: 'Month 2', DataCost: 0, MarketingSpend: 500, AdSpend: 1000 },
    { month: 'Month 3', DataCost: 1000, MarketingSpend: 500, AdSpend: 1300 },
    { month: 'Month 4', DataCost: 0, MarketingSpend: 500, AdSpend: 1600 },
    { month: 'Month 5', DataCost: 0, MarketingSpend: 500, AdSpend: 2000 },
    { month: 'Month 6', DataCost: 1000, MarketingSpend: 500, AdSpend: 2400 },
    { month: 'Month 7', DataCost: 0, MarketingSpend: 500, AdSpend: 2800 },
    { month: 'Month 8', DataCost: 0, MarketingSpend: 500, AdSpend: 3200 },
    { month: 'Month 9', DataCost: 1000, MarketingSpend: 500, AdSpend: 3600 },
    { month: 'Month 10', DataCost: 0, MarketingSpend: 500, AdSpend: 4000 },
    { month: 'Month 11', DataCost: 0, MarketingSpend: 500, AdSpend: 4400 },
    { month: 'Month 12', DataCost: 1000, MarketingSpend: 500, AdSpend: 4800 },
];

const CostDiagram = () => {
    return (
        <div className="cost-diagram">
            <h2 className='text-white'>Cost Efficiency Over Time</h2>
            <p>
            This chart shows the contrast between Artemis marketing spend after initial data purchasing versus the escalating costs associated with running continuous ads. The Artemis program is optimized for driving sustainable site traffic by leveraging initial data investments that reduce ongoing costs, allowing for a more efficient allocation of marketing budgets. With Artemis, businesses can achieve steady engagement and high-quality traffic without the rising expenses typically associated with continuous ad spending. This data-driven approach ensures that marketing efforts are not only cost-effective but also strategically focused on building long-term, meaningful audience relationships.
            </p>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend wrapperStyle={{ fontSize: '10px' }} />
                    <Line type="monotone" dataKey="DataCost" stroke="#8884d8" name="Data Cost (Initial)" dot={{ r: 3 }} />
                    <Line type="monotone" dataKey="MarketingSpend" stroke="#82ca9d" name="Marketing Spend (Artemis)" dot={{ r: 3 }} />
                    <Line type="monotone" dataKey="AdSpend" stroke="#ff6f61" name="Ad Spend (Rising)" dot={{ r: 3 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CostDiagram;
