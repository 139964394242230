import React from 'react';

const UserIntentCard = ({ user }) => {
    return (
        <div className="intent-card">
            <table className="user-info-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Intent</th>
                        <th>Location</th>
                        <th>Facebook</th>
                        <th>Address</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{user.FIRST_NAME} ****</td>
                        <td>{user.INTENT || '****'}</td>
                        <td>{user.CITY && user.STATE && user.ZIP ? `${user.CITY}, ${user.STATE} ${user.ZIP}` : '****'}</td>
                        <td>{user.FB_URL ? '****' : '****'}</td>
                        <td>{user.ADDRESS_1 || '****'}</td>
                        <td>{user.PHONE || '****'}</td>
                        <td>{user.EMAIL || '****'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default UserIntentCard;
