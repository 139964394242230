import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import './UserTable.css'; // Import the CSS file with the prefixed styles

export default function UserTable(props) {

    const extractLatLong = async (row) => {
        // Check if there are any access points and retrieve the top one based on hit_count
        const topAccessPoint = row.access_points.length > 0 
            ? row.access_points.sort((a, b) => b.hit_count - a.hit_count)[0] 
            : null;
    
        // Proceed only if a valid access point is found and IP is compatible
        if (topAccessPoint && topAccessPoint.ip !== "127.0.0.1" && topAccessPoint.ip !== "None") {
            const { lat, lon } = topAccessPoint.location_data || {}; // Destructure lat, lon safely
    
            if (lat != null && lon != null) {
                // Call the parent component's handleMarkerClick with the coordinates
                await props.handleMarkerClick(lat, lon);
            } else {
                console.error("Location data is missing for the top access point.");
            }
        } else {
            console.error("No compatible access points found or IP is invalid.");
        }
    };
    

    return (
        <div className="city-summary-container py-3 px-3 b-c mw-95 mx-auto">
            <MDBTable striped bordered hover className="city-summary-max-width city-summary-table">
                <MDBTableHead className="city-summary-table-head">
                    <tr>
                        <th className="city-summary-header-cell" scope="col">User ID</th>
                        <th className="city-summary-header-cell" scope="col">Requests</th>
                        <th className="city-summary-header-cell" scope="col">Access Points</th>
                        <th className="city-summary-header-cell" scope="col">Most Frequent</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody className="city-summary-table-body">
                    {props.rows.map((row, index) => (
                        <tr key={index}>
                            <td className="city-summary-table-cell">{row["user_id"]}</td>
                            <td className="city-summary-table-cell">{row["request"]}</td>
                            <td className="city-summary-table-cell">{row["access_points"].length}</td>
                            <td 
                                onClick={() => extractLatLong(row)} 
                                className="city-summary-table-cell"
                            >
                                {row["access_points"].length > 0 
                                    ? row["access_points"].sort((a, b) => b.hit_count - a.hit_count)[0]['ip'] 
                                    : "None"}
                            </td>

                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
        </div>
    );
}
