import React from 'react';
import SplashNav from '../components/SplashNav';
import GeofenceCard from '../components/GeofenceCard';
import geofenceData from '../data/geofences.json'; // Sample geofence data
import './EngagePage.css';

const EngagePage = () => {
    return (
        <div className="engage-page">
            <SplashNav />
            <div className="page-content fade-in">
                <div className="title-section">
                    <img src="/engage.png" alt="Geofence Logo" className="logo-image" />
                    <h2 className="page-title">Engage: Analyze a Localized Area with Precision</h2>
                    <h6 className="subtitle">Explore how you can engage with a localized community</h6>
                    <p className="description mw-95">
                        Engage showcases our ability to analyze a precise area with detail, allowing users to define any area within a specified radius and analyze data within these bounds. This tool is ideal for location-based analysis, targeted outreach, and spatial insights. Engage can be combined with any of our other data services to further gain understanding about a local community.
                    </p>
                </div>
                <div className="geofence-cards">
                    {geofenceData.geofences.map((geofence, index) => (
                        <GeofenceCard key={index} geofence={geofence} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EngagePage;
