import React, { useState } from 'react';
import './SplashNav.css'; // Import the associated CSS file
import { useNavigate } from 'react-router-dom';

export default function SplashNav(props) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [rotation, setRotation] = useState(0);
    const scrollY = props.scrollY ? props.scrollY : 0;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    };

    const handleHover = () => {
        setRotation(rotation + 90); // Increment rotation by 90 degrees on each hover
    };

    return (
        <header className={`splash-container ${scrollY * 6.5 > 200 ? "see-through" : "reverse-see-through"}`}>
            <div className={`splash ${scrollY * 6.5 > 200 ? "no-pad-smooth" : "re-pad-smooth"}`}>
                <div className="splash-logo">
                    <img src="/favicon.ico" alt="Prometheus Logo" className="logo" />
                    <span className="logo-text" onClick={() => navigate('/')}>PROMETHEUS</span>
                </div>

                <div className="splash-menu-icon" onClick={toggleMenu} onMouseEnter={handleHover}>
                    <div className="hexagon" style={{ transform: `rotate(${rotation}deg)` }}>
                        <div className="menu-lines"></div>
                    </div>
                </div>

                <div className={`splash-menu mt-3 ${isMenuOpen ? 'active fade-in' : ''}`}>
                    <>
                        {/* Services Dropdown */}
                        <div className="services-dropdown">
                            <button className="splash-contact my-2" onClick={toggleServices}>SAMPLES</button>
                            {isServicesOpen && (
                                <div className="dropdown-menu fade-in">
                                    <li><button className="splash-contact my-2 sm" onClick={() => navigate('/athena-sample')}>ATHENA</button></li>
                                    <li><button className="splash-contact my-2 sm" onClick={() => navigate('/artemis')}>ARTEMIS</button></li>
                                    <li><button className="splash-contact my-2 sm" onClick={() => navigate('/engage-sample')}>ENGAGE</button></li>
                                    <li><button className="splash-contact my-2 sm" onClick={() => navigate('/harvest-sample')}>HARVEST</button></li>
                                    <li><button className="splash-contact my-2 sm" onClick={() => navigate('/mine-sample')}>MINE</button></li>
                                </div>
                            )}
                        </div>
                        {!isServicesOpen && (
                        <>
                        <li><button className="splash-contact my-2" onClick={() => navigate('/about-us')}>ABOUT US</button></li>
                        <li><button className="splash-contact my-2" onClick={() => navigate('/private-sample')}>PRIVATE SAMPLE</button></li>
                        <li><button className="splash-contact my-2" onClick={() => navigate('/privacy')}>PRIVACY POLICY</button></li>
                        <li><button className="splash-contact my-2" onClick={() => navigate('/contact')}>CONTACT US</button></li>
                        <li><button className="splash-contact my-2" onClick={() => navigate('/login')}>MEMBERS AREA</button></li>
                        </>
                      )}
                    </>
                </div>
            </div>
        </header>
    );
}
